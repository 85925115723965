// eslint-disable-next-line
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BaseVue from "@/plugin/BaseVue";
import * as model from "./Model";
import axios from "axios";
import AWS from "aws-sdk";
import VideoHls from "@/components/VideoHls/VideoHls.vue";
import VideoHlsClass from "@/components/VideoHls/VideoHls";
import Tag from "@/components/Tag/Tag.vue";
import TextareaAutoResize from "@/components/TextareaAutoResize/TextareaAutoResize.vue";
import InputPrice from "@/components/InputPrice/InputPrice.vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "./img/image-error.png",
  loading: "./img/image-error.png",
  attempt: 1,
});

@Component({
  components: {
    VideoHls,
    Tag,
    TextareaAutoResize,
    InputPrice,
  },
})
export default class extends BaseVue {
  public model: model.Model = new model.Model();
  public created() {
    const routeInstance = this.$route;
    this.setMeta(routeInstance);
  }
  public async mounted() {
    await this.authCheck();
    if (this.$route.params.contentCode != null) {
      this.model.contentCode = this.$route.params.contentCode;
    } else {
      throw new Error();
    }
    let response: any;
    this.loadingOn();
    await this.mainLoad();
    this.loadingOff();
  }
  public async mainLoad() {
    let response: any;
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ContentEdit/Main`,
        {
          code: this.model.contentCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        if (response.status != 200) {
          throw new Error();
        }
        if (response.data.errors.length > 0) {
          throw new Error();
        }
        response = response.data;
        this.model.title.value = response.title;
        this.model.contentId = response.contentId;
        this.model.explanation.value = response.explanation;
        this.model.sale = response.sale;
        if (response.price != null) {
          this.model.price.value = response.price.toString();
        }
        if (response.day != null) {
          this.model.day.value = response.day.toString();
        }
        this.model.limited = response.limited;
        this.model.tags.values = [];
        if (response.tags.length == 0) {
          this.model.tags.values.push(
            new model.InputTag(this.model.tags.maxLength)
          );
        } else {
          for (const item1 of response.tags) {
            this.model.tags.values.push(
              new model.InputTag(this.model.tags.maxLength, 0, item1)
            );
          }
        }
        this.model.contentGroups = [];
        for (const item1 of response.contentGroups) {
          this.model.contentGroups.push(
            new model.ContentGroup(item1.code, item1.name, item1.checked)
          );
        }
        this.model.plans = [];
        for (const item1 of response.plans) {
          this.model.plans.push(
            new model.Plan(item1.code, item1.name, item1.checked)
          );
        }

        this.model.thumbnail.type = model.InputThumbnailType.fileUpload;
        this.model.thumbnail.defaultFileImageUrl = null;
        this.model.thumbnail.loacalFilePath = null;
        this.model.thumbnail.base64Src = null;
        this.model.thumbnail.snapShotWidth = null;
        this.model.thumbnail.snapShotHeight = null;
        this.model.thumbnail.snapShotCount = null;
        this.model.thumbnail.thumbnailBaseUrlDefault = null;
        this.model.thumbnail.thumbnailBaseUrlTemp = null;
        this.model.thumbnail.snapShotList = null;
        this.model.thumbnail.snapShotSelected = null;

        if (response.thumbnailImageUrl != "") {
          this.model.thumbnail.defaultFileImageUrl = response.thumbnailImageUrl;
        }

        this.model.media.target = model.InputMediaTarget.temp;
        this.model.media.status = model.InputMediaStatus.loading;
        this.model.media.params.statusText = "";
        this.model.media.params.cancel = false;
        this.model.media.params.progress = 0;
        this.model.media.contentTempFileId = null;

        if (response.convertDefaultStatusResponse != null) {
          switch (response.convertDefaultStatusResponse.ready) {
            case true:
              this.model.media.status = model.InputMediaStatus.completed;
              this.model.media.target = model.InputMediaTarget.default;
              this.$nextTick(function () {
                (this.$refs.videoHls as VideoHlsClass).setupVideo(
                  response.convertDefaultStatusResponse.contentFileUrl
                );
              });
              break;
            case false:
              this.model.media.status = model.InputMediaStatus.converting;
              this.model.media.target = model.InputMediaTarget.default;
              this.model.media.params.progress =
                response.convertDefaultStatusResponse.percent;
              this.mediaUpdateConvertDefaultStatus();
              break;
          }
        } else {
          this.model.media.status = model.InputMediaStatus.ready;
        }
        if (response.thumbnailDefault != null) {
          this.setThumbnail("default", response.thumbnailDefault);
        }

        this.model.loading = false;
      })
      .catch((ex) => {
        throw ex;
      });
  }
  public setThumbnail(type: string, response: any) {
    switch (type) {
      case "default":
        this.model.thumbnail.thumbnailBaseUrlDefault = response.baseUrl;
        break;
      case "temp":
        this.model.thumbnail.thumbnailBaseUrlTemp = response.baseUrl;
        break;
    }
    this.model.thumbnail.snapShotWidth = response.snapShotWidth;
    this.model.thumbnail.snapShotHeight = response.snapShotHeight;
    this.model.thumbnail.snapShotCount = response.snapShotCount;
    this.model.thumbnail.snapShotList =
      new Array<model.InputThumbnailSnapShotListItem>();
    const split = 100;
    let left = 0;
    let number = 0;
    for (let i = 1; i <= split; i++) {
      if (i == 1) {
        number = 1;
      } else if (i == split) {
        number = Number(this.model.thumbnail.snapShotCount);
      } else {
        number = Math.floor(
          (Number(this.model.thumbnail.snapShotCount) / split) * i + 1
        );
      }
      left = (i - 1) * Number(this.model.thumbnail.snapShotWidth);
      this.model.thumbnail.snapShotList.push(
        new model.InputThumbnailSnapShotListItem(i, left, number)
      );
    }
  }
  public async mediaUpdateConvertDefaultStatus() {
    let response: any;
    do {
      this.loadingOn();
      await axios
        .post(
          `https://${process.env.VUE_APP_API_URL}/ContentEdit/ConvertDefaultStatus`,
          {
            contentId: this.model.contentId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + (await this.creatorUserManager.getJwtToken()),
            },
          }
        )
        .then((response) => {
          if (response.status != 200) {
            throw new Error();
          }
          if (response.data.errors.length > 0) {
            throw new Error();
          }
          response = response.data;
          switch (response.ready) {
            case true:
              this.model.media.status = model.InputMediaStatus.completed;
              this.model.media.target = model.InputMediaTarget.default;
              this.model.media.params.progress = response.percent;
              this.$nextTick(function () {
                (this.$refs.videoHls as VideoHlsClass).setupVideo(
                  response.contentFileUrl
                );
              });
              break;
            case false:
              this.model.media.status = model.InputMediaStatus.converting;
              this.model.media.target = model.InputMediaTarget.default;
              this.model.media.params.statusText = "変換処理中...";
              this.model.media.params.progress = response.percent;
              break;
          }
        })
        .catch((ex) => {
          throw ex;
        });
      this.loadingOff();
      await this.sleep(2000);
    } while (!response.ready);
  }
  public async mediaCancelCheck(): Promise<boolean> {
    if (!this.model.media.params.cancel) {
      return false;
    }
    this.model.media.params.progress = 0;
    await this.sleep(2000);
    this.model.media.status = model.InputMediaStatus.ready;
    this.model.media.params.cancel = false;
    this.model.media.contentTempFileId = null;
    this.model.saveButtonActive = true;
    return true;
  }
  public async mediaCancel() {
    if (!window.confirm("取消しますか？")) {
      return;
    }
    this.model.media.params.cancel = true;
    this.model.media.params.statusText = "取消中...";
  }
  public async mediaReUpload() {
    if (!window.confirm("再アップロードを行いますか？")) {
      return;
    }
    this.$nextTick(function () {
      (this.$refs.videoHls as VideoHlsClass).destroyVideo();
    });
    this.model.media.status = model.InputMediaStatus.ready;
    this.model.media.contentTempFileId = null;
    this.model.media.target = model.InputMediaTarget.temp;
    this.model.thumbnail.type = model.InputThumbnailType.fileUpload;
    this.model.thumbnail.snapShotList = null;
    this.model.thumbnail.snapShotSelected = null;
    this.model.thumbnail.thumbnailBaseUrlTemp = null;
    this.model.thumbnail.thumbnailBaseUrlDefault = null;
    this.model.thumbnail.snapShotCount = null;
    this.model.thumbnail.snapShotHeight = null;
    this.model.thumbnail.snapShotWidth = null;
  }
  public validationCheck(target = "", inputing = false, auto = false): boolean {
    if (!auto && !inputing) {
      this.model.saveButtonCompleted = false;
    }
    let result = true;
    let input: any;
    let errors: any;
    if (target == "all" || target == "title") {
      input = this.model.title;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = input.value.trim();
        input.value = this.hankaku2Zenkaku(input.value);
      }
      if (input.value.length === 0) {
        errors.push(new model.Error("必須項目です"));
      } else if (input.value.length < input.minLength) {
        errors.push(
          new model.Error(`${input.minLength}文字以上で入力してください`)
        );
      }
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "explanation") {
      input = this.model.explanation;
      errors = new Array<model.Error>();
      //
      if (input.value.length > input.maxLength) {
        errors.push(
          new model.Error(`${input.maxLength}文字以下で入力してください`)
        );
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "tags") {
      input = this.model.tags;
      errors = new Array<model.Error>();
      //
      for (let i = 0; i < input.values.length; i++) {
        const item = input.values[i];
        if (!inputing) {
          input.values[i].value = item.value.trim();
          input.values[i].value = this.hankaku2Zenkaku(input.values[i].value);
        }
        if (item.value.length > item.maxLength) {
          errors.push(
            new model.Error(
              `${input.values.indexOf(item) + 1}個目のアイテム${
                input.maxLength
              }文字以下で入力してください`,
              i
            )
          );
        }
        for (let i2 = 0; i2 < input.values.length; i2++) {
          const item2 = input.values[i2];
          if (
            i !== i2 &&
            input.values[i].value != "" &&
            input.values[i].value === input.values[i2].value
          ) {
            errors.push(
              new model.Error(
                `${input.values.indexOf(item) + 1}個目のアイテムと${
                  input.values.indexOf(item2) + 1
                }個目のアイテムが重複しています`,
                i
              )
            );
          }
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError()) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "price") {
      input = this.model.price;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = this.hankaku2Zenkaku(input.value);
        input.value = input.value.replace(/[^0-9]/g, "");
      }
      if (this.model.sale) {
        if (input.value.length === 0) {
          errors.push(new model.Error("単品販売単価を設定してください"));
        } else if (input.value < 100) {
          errors.push(
            new model.Error(`単品販売単価は\\100以上で設定してください`)
          );
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "day") {
      input = this.model.day;
      errors = new Array<model.Error>();
      //
      if (!inputing) {
        input.value = this.hankaku2Zenkaku(input.value);
        input.value = input.value.replace(/[^0-9]/g, "");
      }

      if (this.model.sale) {
        if (input.value.length === 0) {
          errors.push(new model.Error("必須項目です"));
        } else {
          if (input.value.length > input.maxLength) {
            errors.push(new model.Error(`999日以下を入力してください`));
          } else {
            if (Number(input.value) == 0) {
              errors.push(new model.Error(`1日以上を入力してください`));
            }
          }
        }
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "media") {
      input = this.model.media;
      errors = new Array<model.Error>();
      //
      if (
        input.target == model.InputMediaTarget.temp &&
        input.contentTempFileId == null
      ) {
        errors.push(new model.Error("必須項目です"));
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    if (target == "all" || target == "thumbnail") {
      input = this.model.thumbnail;
      errors = new Array<model.Error>();
      //
      if (!input.isSetSrc) {
        errors.push(new model.Error("必須項目です"));
      }
      //
      if (inputing) {
        if (input.errors.length > errors.length) {
          input.errors = errors;
        }
      } else {
        input.errors = errors;
      }
      if (input.isError) {
        result = false;
      }
      //
    }
    return result;
  }
  public async changeInputMediaFile(e: any | null) {
    const file = e.target.files[0];
    if (file == null) {
      throw new Error();
    }
    this.model.saveButtonActive = false;
    this.model.media.status = model.InputMediaStatus.uploading;
    this.model.media.params.statusText = "アップロード準備中...";
    this.model.media.params.progress = 0;

    const partSize = 1024 * 1024 * 5; // 5MB
    let partCount = 0;
    for (let rangeStart = 0; rangeStart < file.size; rangeStart += partSize) {
      partCount++;
    }

    let response: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ContentEdit/FileUpload`,
        {
          contentId: this.model.contentId,
          partCount: partCount,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        if (response.status != 200) {
          throw new Error();
        }
        if (response.data.errors.length > 0) {
          throw new Error();
        }
        response = response.data;
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();

    this.model.media.params.progress = 10;
    this.model.media.params.statusText = "アップロード中...";

    if (await this.mediaCancelCheck()) {
      return;
    }

    this.model.media.contentTempFileId = response.contentTempFileId;

    let partNumber = 0;
    const parts: Array<any> = [];

    await this.creatorUserManager.credential();

    const s3 = new AWS.S3({
      params: {
        Region: response.region,
        Bucket: response.bucket,
      },
    });

    for (let rangeStart = 0; rangeStart < file.size; rangeStart += partSize) {
      partNumber++;
      const end = Math.min(rangeStart + partSize, file.size);

      const partData: Uint8Array = await new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (event: any) => {
          const data = event.target.result;
          const byte = new Uint8Array(data);
          resolve(byte);
          fileReader.abort();
        };
        const blob2 = file.slice(rangeStart, end);
        fileReader.readAsArrayBuffer(blob2);
      });

      const uploadPartResult = await s3
        .uploadPart({
          Bucket: response.bucket,
          Key: response.key,
          UploadId: response.uploadId,
          PartNumber: partNumber,
          Body: partData,
        })
        .promise();
      parts.push({
        partNumber: partNumber,
        eTag: uploadPartResult.ETag,
      });

      if (await this.mediaCancelCheck()) {
        return;
      }

      this.model.media.params.progress =
        Math.floor((((end / file.size) * 100) / 100) * 80) + 10;
    }

    const key = response.key;

    this.model.media.params.statusText = "アップロード完了処理中...";

    let uploadCompleteResponse: any;
    this.loadingOn();
    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ContentEdit/FileUploadComplete`,
        {
          contentTempFileId: this.model.media.contentTempFileId,
          parts: parts,
          key: key,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        if (response.status != 200) {
          throw new Error();
        }
        if (response.data.errors.length > 0) {
          throw new Error();
        }
        response = response.data;
      })
      .catch((ex) => {
        throw ex;
      });
    this.loadingOff();

    if (await this.mediaCancelCheck()) {
      return;
    }

    this.model.media.status = model.InputMediaStatus.converting;
    this.model.media.params.progress = 0;
    this.model.media.params.statusText = "進行状況確認中...";

    do {
      await this.sleep(5000);
      if (await this.mediaCancelCheck()) {
        return;
      }
      this.loadingOn();
      await axios
        .post(
          `https://${process.env.VUE_APP_API_URL}/ContentEdit/ConvertTempStatus`,
          {
            contentTempFileId: this.model.media.contentTempFileId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + (await this.creatorUserManager.getJwtToken()),
            },
          }
        )
        .then((response) => {
          if (response.status != 200) {
            throw new Error();
          }
          if (response.data.errors.length > 0) {
            throw new Error();
          }
          response = response.data;
          if (!response.ready) {
            this.model.media.status = model.InputMediaStatus.converting;
            this.model.media.params.statusText = "変換処理中...";
            this.model.media.params.progress = response.percent;
          }
        })
        .catch((ex) => {
          throw ex;
        });
      this.loadingOff();
    } while (!response.ready);

    this.model.media.status = model.InputMediaStatus.completed;
    this.model.media.params.progress = response.percent;
    this.validationCheck("media");
    this.$nextTick(function () {
      (this.$refs.videoHls as VideoHlsClass).setupVideo(response.tempFilePath);
    });
    this.model.saveButtonActive = true;
  }
  @Watch("model.media.target", { deep: true })
  @Watch("model.media.contentTempFileId", { deep: true })
  public async changeThumbnailMediaSelect() {
    if (this.model.loading) {
      return;
    }
    if (
      this.model.media.target == model.InputMediaTarget.temp &&
      this.model.media.contentTempFileId == null
    ) {
      this.model.thumbnail.type = model.InputThumbnailType.fileUpload;
      this.model.thumbnail.thumbnailBaseUrlTemp = null;
      this.model.thumbnail.snapShotSelected = null;
    }
    if (
      this.model.media.target == model.InputMediaTarget.temp &&
      this.model.media.contentTempFileId != null
    ) {
      let response: any;
      do {
        await this.sleep(5000);
        this.loadingOn();
        await axios
          .post(
            `https://${process.env.VUE_APP_API_URL}/ContentEdit/ThumbnailInfo`,
            {
              contentTempFileId: this.model.media.contentTempFileId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "Bearer " + (await this.creatorUserManager.getJwtToken()),
              },
            }
          )
          .then((response) => {
            if (response.status != 200) {
              throw new Error();
            }
            if (response.data.errors.length > 0) {
              throw new Error();
            }
            response = response.data;
          })
          .catch((ex) => {
            throw ex;
          });
        this.loadingOff();
      } while (!response.ready);
      this.setThumbnail("temp", response);
    }

    // 画面読み込み中：スキップ
    // temp→default：スキップ(tempFileIdは変わらない為)
    // default→temp：初期化のみ
    //   temp && contentTempFileId=null
    // contentTempFileId=null→contentTempFileId=notNull：Thumbnailロード
    //   temp && contentTempFileId=notNull
  }
  public async changeThumbnailType(type: string) {
    if (type == "fileUpload") {
      this.model.thumbnail.type = model.InputThumbnailType.fileUpload;
    }
    if (type == "mediaSelect") {
      this.model.thumbnail.type = model.InputThumbnailType.mediaSelect;
    }
  }
  public changeSnapShot(count: number) {
    this.model.thumbnail.loacalFilePath = null;
    this.model.thumbnail.base64Src = null;
    this.model.thumbnail.defaultFileImageUrl = null;
    this.model.thumbnail.snapShotSelected = count;
    this.validationCheck("thumbnail");
    // const temp = document.getElementById("snapShot");
    // if (temp != null) {
    //   temp.scrollLeft =
    //     (count + 1) * (Number(this.model.thumbnail.snapShotWidth) + 15) -
    //     (700 / 2 + 40 + Number(this.model.thumbnail.snapShotWidth) / 2);
    // }
  }
  public async deleteThumbnail() {
    if (!window.confirm("削除しますか？")) {
      return;
    }
    const element: any = document.getElementById("model.thumbnail");
    if (element != null) {
      element.value = "";
    }
    this.model.thumbnail.base64Src = null;
    this.model.thumbnail.loacalFilePath = null;
    this.model.thumbnail.snapShotSelected = null;
    this.model.thumbnail.defaultFileImageUrl = null;
  }
  public async changeThumbnailLoacalFilePath(e: any) {
    const file = e.target.files[0];
    if (file == null) {
      return;
    }

    this.model.saveButtonActive = false;
    this.model.thumbnail.base64Src = null;
    this.model.thumbnail.loacalFilePath = null;
    this.model.thumbnail.snapShotSelected = null;
    this.model.thumbnail.defaultFileImageUrl = null;
    await this.sleep(1000);
    this.model.thumbnail.loacalFilePath = file.name;
    const reader = new FileReader();
    reader.onload = this.changeThumbnailLoacalFileLoaded;
    reader.readAsDataURL(file);
  }
  public changeThumbnailLoacalFileLoaded(event: any) {
    this.model.thumbnail.base64Src = event.target.result;
    this.validationCheck("thumbnail");
    this.model.saveButtonActive = true;
  }
  public async save() {
    this.model.loading = true;
    this.model.saveButtonActive = false;
    this.model.saveButtonLoading = true;
    const validationCheck = this.validationCheck("all");
    if (!validationCheck) {
      await this.sleep(1000);
      this.model.saveButtonLoading = false;
      this.model.saveButtonActive = true;
      this.model.loading = false;
      return;
    }
    this.loadingOn();
    const tags = new Array<string>();
    for (const item1 of this.model.tags.values) {
      if (item1.value == "") {
        continue;
      }
      tags.push(item1.value);
    }
    const contentGroups = new Array<string>();
    for (const item1 of this.model.contentGroups) {
      if (!item1.checked) {
        continue;
      }
      contentGroups.push(item1.code);
    }
    const plans = new Array<string>();
    for (const item1 of this.model.plans) {
      if (!item1.checked) {
        continue;
      }
      plans.push(item1.code);
    }
    let price;
    if (this.model.price.value == "") {
      price = null;
    } else {
      price = Number(this.model.price.value);
    }
    let day;
    if (this.model.day.value == "") {
      day = null;
    } else {
      day = Number(this.model.day.value);
    }

    let uploadId: number | null = null;
    let key: string | null = null;
    const parts: Array<any> = [];

    let response: any;

    if (this.model.thumbnail.base64Src != null) {
      await axios
        .post(
          `https://${process.env.VUE_APP_API_URL}/ContentEdit/ThumbnailUpload`,
          {
            contentId: this.model.contentId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer " + (await this.creatorUserManager.getJwtToken()),
            },
          }
        )
        .then((response) => {
          response = response.data;
          uploadId = response.uploadId;
          key = response.key;
        })
        .catch((ex) => {
          throw ex;
        });

      await this.creatorUserManager.credential();

      const s3 = new AWS.S3({
        params: {
          Region: response.region,
          Bucket: response.bucket,
        },
      });
      const uploadPartResult = await s3
        .uploadPart({
          Bucket: response.bucket,
          Key: response.key,
          UploadId: response.uploadId,
          PartNumber: 1,
          Body: this.base64toUint8Array(
            this.model.thumbnail.base64Src.split(",")[1]
          ),
        })
        .promise();
      parts.push({
        partNumber: 1,
        eTag: uploadPartResult.ETag,
      });
    }

    let thumbnailSnapShotSelected = null;
    if (this.model.thumbnail.snapShotSelected != null) {
      thumbnailSnapShotSelected = this.model.thumbnail.snapShotSelectedNumber;
    }
    let thumbnailFileUpload = null;
    if (uploadId != null) {
      thumbnailFileUpload = {
        uploadId: uploadId,
        parts: parts,
        key: key,
      };
    }

    await axios
      .post(
        `https://${process.env.VUE_APP_API_URL}/ContentEdit/Save`,
        {
          contentId: this.model.contentId,
          title: this.model.title.value,
          explanation: this.model.explanation.value,
          sale: this.model.sale,
          limited: this.model.limited,
          price: price,
          day: day,
          tags,
          contentGroups: contentGroups,
          plans: plans,
          contentTempFileId: this.model.media.contentTempFileId,
          thumbnailSnapShotSelected: thumbnailSnapShotSelected,
          thumbnailFileUpload: thumbnailFileUpload,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await this.creatorUserManager.getJwtToken()),
          },
        }
      )
      .then((response) => {
        response = response.data;
      })
      .catch((ex) => {
        throw ex;
      });
    await this.mainLoad();
    this.model.saveButtonCompleted = true;
    this.model.saveButtonActive = true;
    this.model.saveButtonLoading = false;
    this.model.loading = false;
    this.loadingOff();
  }
}
