var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page", class: { loading: _vm.model.loading } },
    [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorDashboard" } } },
                [_vm._v("ダッシュボード")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c(
                "router-link",
                { attrs: { to: { name: "CreatorContents" } } },
                [_vm._v("動画")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("fa-icon", { attrs: { icon: "angle-right" } }),
              _c("span", [_vm._v("編集")]),
            ],
            1
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "photo-video" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("メディア")]),
          _c("div", { staticClass: "body" }, [
            _c("div", { staticClass: "mediaUpload" }, [
              _vm.model.media.status == "ready"
                ? _c(
                    "div",
                    { staticClass: "ready" },
                    [
                      _c("fa-icon", { attrs: { icon: "upload" } }),
                      _c("strong", [_vm._v("アップロードするファイルを選択")]),
                      _c("span", [
                        _vm._v("または動画ファイルをドラック＆ドロップします"),
                      ]),
                      _c("input", {
                        attrs: { type: "file", accept: "video/*" },
                        on: { input: _vm.changeInputMediaFile },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.model.media.status == "uploading"
                ? _c("div", { staticClass: "statusBox uploading" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass: "progressBar",
                        class: {
                          green: !_vm.model.media.params.cancel,
                          red: _vm.model.media.params.cancel,
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.model.media.params.statusText)),
                        ]),
                        _c("div", { staticClass: "bar" }, [
                          _c("div", {
                            staticClass: "value",
                            style: {
                              width: _vm.model.media.params.progress + "%",
                            },
                          }),
                          _c(
                            "span",
                            {
                              class: {
                                over: _vm.model.media.params.progress > 50,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.model.media.params.progress) + "%"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "stop" }, [
                      _c(
                        "button",
                        {
                          attrs: { disabled: _vm.model.media.params.cancel },
                          on: { click: _vm.mediaCancel },
                        },
                        [_vm._v(" 取消 ")]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.media.status == "converting"
                ? _c("div", { staticClass: "statusBox converting" }, [
                    _vm.model.media.target == "temp"
                      ? _c("ul", { attrs: { clss: "step" } }, [
                          _c("li", [_vm._v("1.アップロード")]),
                          _c("li", [_vm._v("2.ファイル変換")]),
                          _c("li", [_vm._v("3.準備完了")]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "progressBar",
                        class: {
                          green: !_vm.model.media.params.cancel,
                          red: _vm.model.media.params.cancel,
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.model.media.params.statusText)),
                        ]),
                        _c("div", { staticClass: "bar" }, [
                          _c("div", {
                            staticClass: "value",
                            style: {
                              width: _vm.model.media.params.progress + "%",
                            },
                          }),
                          _c(
                            "span",
                            {
                              class: {
                                over: _vm.model.media.params.progress > 50,
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.model.media.params.progress) + "%"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "stop" }, [
                      _vm.model.media.target == "temp"
                        ? _c(
                            "button",
                            {
                              attrs: {
                                disabled: _vm.model.media.params.cancel,
                              },
                              on: { click: _vm.mediaCancel },
                            },
                            [_vm._v(" 取消 ")]
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.media.status == "loading"
                ? _c("div", { staticClass: "statusBox pending" }, [
                    _vm._v(" Loading... "),
                  ])
                : _vm._e(),
              _vm.model.media.status == "completed"
                ? _c(
                    "div",
                    { staticClass: "completed" },
                    [
                      _c("VideoHls", { ref: "videoHls" }),
                      _c("button", { on: { click: _vm.mediaReUpload } }, [
                        _vm._v("再アップロード"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.media.errors, function (item1) {
              return _c("div", { key: _vm.model.media.errors.indexOf(item1) }, [
                _c(
                  "div",
                  [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item1.message))]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "heading" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("タイトル")]),
          _c("div", { staticClass: "body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.title.value,
                  expression: "model.title.value",
                },
              ],
              class: { error: _vm.model.title.isError },
              attrs: { type: "text" },
              domProps: { value: _vm.model.title.value },
              on: {
                change: function ($event) {
                  return _vm.validationCheck("title")
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.model.title, "value", $event.target.value)
                  },
                  function ($event) {
                    return _vm.validationCheck("title", true)
                  },
                ],
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.title.errors, function (item1) {
              return _c("div", { key: _vm.model.title.errors.indexOf(item1) }, [
                _c(
                  "div",
                  [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item1.message))]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "align-justify" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("説明")]),
          _c(
            "div",
            { staticClass: "body" },
            [
              _c("textarea-auto-resize", {
                class: { error: _vm.model.explanation.isError },
                on: {
                  onChange: function ($event) {
                    return _vm.validationCheck("explanation")
                  },
                  onInput: function ($event) {
                    return _vm.validationCheck("explanation", true)
                  },
                },
                model: {
                  value: _vm.model.explanation,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "explanation", $$v)
                  },
                  expression: "model.explanation",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.explanation.errors, function (item1) {
              return _c(
                "div",
                { key: _vm.model.explanation.errors.indexOf(item1) },
                [
                  _c(
                    "div",
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "photo-video" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("タグ")]),
          _c(
            "div",
            { staticClass: "body" },
            [
              _c("tag", {
                on: {
                  onChange: function ($event) {
                    return _vm.validationCheck("tags")
                  },
                  onInput: function ($event) {
                    return _vm.validationCheck("tags", true)
                  },
                },
                model: {
                  value: _vm.model.tags,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "tags", $$v)
                  },
                  expression: "model.tags",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.tags.errors, function (item1) {
              return _c("div", { key: _vm.model.tags.errors.indexOf(item1) }, [
                _c(
                  "div",
                  [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                  1
                ),
                _c("span", [_vm._v(_vm._s(item1.message))]),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "folder" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("グループ")]),
          _c("div", { staticClass: "body" }, [
            _c(
              "div",
              { staticClass: "checkbox" },
              _vm._l(_vm.model.contentGroups, function (item1) {
                return _c("div", { key: item1.code }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item1.checked,
                        expression: "item1.checked",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "model.contentGroups[" + item1.code + "]",
                    },
                    domProps: {
                      checked: Array.isArray(item1.checked)
                        ? _vm._i(item1.checked, null) > -1
                        : item1.checked,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = item1.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(item1, "checked", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  item1,
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(item1, "checked", $$c)
                          }
                        },
                        _vm.validationCheck,
                      ],
                    },
                  }),
                  _c(
                    "label",
                    {
                      attrs: { for: "model.contentGroups[" + item1.code + "]" },
                    },
                    [_vm._v(_vm._s(item1.name))]
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "shapes" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("サブスクリプション")]),
          _c("div", { staticClass: "body" }, [
            _c(
              "div",
              { staticClass: "checkbox" },
              _vm._l(_vm.model.plans, function (item1) {
                return _c("div", { key: item1.code }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: item1.checked,
                        expression: "item1.checked",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "model.plans[" + item1.code + "]",
                    },
                    domProps: {
                      checked: Array.isArray(item1.checked)
                        ? _vm._i(item1.checked, null) > -1
                        : item1.checked,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = item1.checked,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(item1, "checked", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  item1,
                                  "checked",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(item1, "checked", $$c)
                          }
                        },
                        _vm.validationCheck,
                      ],
                    },
                  }),
                  _c(
                    "label",
                    { attrs: { for: "model.plans[" + item1.code + "]" } },
                    [_vm._v(_vm._s(item1.name))]
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "image" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("サムネイル")]),
          _c("div", { staticClass: "body" }, [
            _vm.model.thumbnail.isSetSrc
              ? _c("div", { staticClass: "thumbnailImage" }, [
                  _vm.model.thumbnail.base64Src != null
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.model.thumbnail.base64Src,
                            expression: "model.thumbnail.base64Src",
                          },
                        ],
                        attrs: { id: "thumbnailfileUploadImg" },
                      })
                    : _vm._e(),
                  _vm.model.thumbnail.contentTempFileImageUrl != null
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.model.thumbnail.contentTempFileImageUrl,
                            expression:
                              "model.thumbnail.contentTempFileImageUrl",
                          },
                        ],
                        attrs: { id: "thumbnailfileUploadImg" },
                      })
                    : _vm._e(),
                  _vm.model.thumbnail.defaultFileImageUrl != null
                    ? _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.model.thumbnail.defaultFileImageUrl,
                            expression: "model.thumbnail.defaultFileImageUrl",
                          },
                        ],
                        attrs: { id: "thumbnailfileUploadImg" },
                      })
                    : _vm._e(),
                  _c("button", { on: { click: _vm.deleteThumbnail } }, [
                    _c("span", [_vm._v("削除")]),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "tabs" }, [
              _c("div", { staticClass: "header" }, [
                _c(
                  "button",
                  {
                    class: {
                      active: _vm.model.thumbnail.type == "fileUpload",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeThumbnailType("fileUpload")
                      },
                    },
                  },
                  [_c("span", [_vm._v("画像ファイルアップロード")])]
                ),
                _c(
                  "button",
                  {
                    class: {
                      active: _vm.model.thumbnail.type == "mediaSelect",
                    },
                    attrs: {
                      disabled:
                        _vm.model.thumbnail.thumbnailBaseUrlDefault == null &&
                        _vm.model.thumbnail.thumbnailBaseUrlTemp == null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeThumbnailType("mediaSelect")
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v("動画から選択")]),
                    _vm.model.thumbnail.thumbnailBaseUrlDefault == null &&
                    _vm.model.thumbnail.thumbnailBaseUrlTemp == null
                      ? _c("span", { staticClass: "loading" }, [
                          _vm._v("抽出中..."),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm.model.thumbnail.type == "fileUpload"
                ? _c("div", { staticClass: "body" }, [
                    _vm.model.thumbnail.loacalFilePath != null
                      ? _c("span", { staticClass: "path" }, [
                          _vm._v(_vm._s(_vm.model.thumbnail.loacalFilePath)),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "uploadButton" }, [
                      _c(
                        "button",
                        [
                          _c("fa-icon", { attrs: { icon: "folder-open" } }),
                          _c("span", [_vm._v("ファイルを選択する")]),
                        ],
                        1
                      ),
                      _c("input", {
                        attrs: {
                          type: "file",
                          id: "model.thumbnail",
                          accept: "image/*",
                        },
                        on: { input: _vm.changeThumbnailLoacalFilePath },
                      }),
                    ]),
                  ])
                : _vm._e(),
              _vm.model.thumbnail.type == "mediaSelect"
                ? _c("div", { staticClass: "body" }, [
                    _c(
                      "div",
                      { staticClass: "snapShot", attrs: { id: "snapShot" } },
                      _vm._l(
                        _vm.model.thumbnail.snapShotList,
                        function (item1) {
                          return _c(
                            "div",
                            { key: item1.count, staticClass: "loop" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image",
                                  style: {
                                    backgroundImage:
                                      "url(" +
                                      _vm.model.thumbnail.snapShotImageUrl +
                                      ")",
                                    backgroundPositionX: -item1.left + "px",
                                    width:
                                      _vm.model.thumbnail.snapShotWidth + "px",
                                    height:
                                      _vm.model.thumbnail.snapShotHeight + "px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeSnapShot(item1.count)
                                    },
                                  },
                                },
                                [
                                  _vm.model.thumbnail.snapShotSelected ==
                                  item1.count
                                    ? _c("div", {
                                        staticClass: "selected",
                                        style: {
                                          width:
                                            _vm.model.thumbnail.snapShotWidth +
                                            8 +
                                            "px",
                                          height:
                                            _vm.model.thumbnail.snapShotHeight +
                                            8 +
                                            "px",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "errors" },
            _vm._l(_vm.model.thumbnail.errors, function (item1) {
              return _c(
                "div",
                { key: _vm.model.thumbnail.errors.indexOf(item1) },
                [
                  _c(
                    "div",
                    [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                    1
                  ),
                  _c("span", [_vm._v(_vm._s(item1.message))]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _c("hr"),
      _c("div", { staticClass: "formGroup" }, [
        _c("div", [_c("fa-icon", { attrs: { icon: "coins" } })], 1),
        _c("div", [
          _c("span", { staticClass: "title" }, [_vm._v("単品販売")]),
          _c("div", { staticClass: "body" }, [
            _c("div", { staticClass: "radioGroup" }, [
              _c("div", { staticClass: "radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.sale,
                      expression: "model.sale",
                    },
                  ],
                  attrs: { type: "radio", id: "model.sale_true" },
                  domProps: {
                    value: true,
                    checked: _vm._q(_vm.model.sale, true),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.model, "sale", true)
                      },
                      _vm.validationCheck,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "model.sale_true" } }, [
                  _vm._v("単品販売する"),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "buySingleItem",
                  class: { disabled: !_vm.model.sale },
                },
                [
                  _c(
                    "div",
                    { staticClass: "rap" },
                    [
                      _c("label", { attrs: { for: "inputPrice" } }, [
                        _vm._v("単価（税込）"),
                      ]),
                      _c("fa-icon", { attrs: { icon: "yen-sign" } }),
                      _c("input-price", {
                        class: { error: _vm.model.price.isError },
                        on: {
                          onChange: function ($event) {
                            return _vm.validationCheck("price")
                          },
                          onInput: function ($event) {
                            return _vm.validationCheck("price", true)
                          },
                        },
                        model: {
                          value: _vm.model.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "price", $$v)
                          },
                          expression: "model.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.price.errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.price.errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("hr"),
                  _c("div", { staticClass: "rap" }, [
                    _c("span", [_vm._v("購入してから")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.day.value,
                          expression: "model.day.value",
                        },
                      ],
                      class: { error: _vm.model.day.isError },
                      attrs: { type: "text" },
                      domProps: { value: _vm.model.day.value },
                      on: {
                        change: function ($event) {
                          return _vm.validationCheck("day")
                        },
                        input: [
                          function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.model.day,
                              "value",
                              $event.target.value
                            )
                          },
                          function ($event) {
                            return _vm.validationCheck("day", true)
                          },
                        ],
                      },
                    }),
                    _c("span", [_vm._v("日間閲覧可能")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "errors" },
                    _vm._l(_vm.model.day.errors, function (item1) {
                      return _c(
                        "div",
                        { key: _vm.model.day.errors.indexOf(item1) },
                        [
                          _c(
                            "div",
                            [
                              _c("fa-icon", {
                                attrs: { icon: "exclamation-circle" },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v(_vm._s(item1.message))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c("div", { staticClass: "radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model.sale,
                      expression: "model.sale",
                    },
                  ],
                  attrs: { type: "radio", id: "model.sale_false" },
                  domProps: {
                    value: false,
                    checked: _vm._q(_vm.model.sale, false),
                  },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(_vm.model, "sale", false)
                      },
                      _vm.validationCheck,
                    ],
                  },
                }),
                _c("label", { attrs: { for: "model.sale_false" } }, [
                  _vm._v("単品販売しない"),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "notBuySingleItem",
                  class: { disabled: _vm.model.sale },
                },
                [
                  _c("div", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.limited,
                          expression: "model.limited",
                        },
                      ],
                      attrs: { type: "radio", id: "model.limited_true" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.model.limited, true),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.model, "limited", true)
                          },
                          _vm.validationCheck,
                        ],
                      },
                    }),
                    _c("label", { attrs: { for: "model.limited_true" } }, [
                      _vm._v("限定公開（サブスクリプション加入後閲覧可能）"),
                    ]),
                  ]),
                  _c("div", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.limited,
                          expression: "model.limited",
                        },
                      ],
                      attrs: { type: "radio", id: "model.limited_false" },
                      domProps: {
                        value: false,
                        checked: _vm._q(_vm.model.limited, false),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.model, "limited", false)
                          },
                          _vm.validationCheck,
                        ],
                      },
                    }),
                    _c("label", { attrs: { for: "model.limited_false" } }, [
                      _vm._v(
                        "一般公開（サブスクリプション非加入でも閲覧可能）"
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "bottom" }, [
        _vm.model.saveButtonCompleted
          ? _c("div", { staticClass: "saveButtonCompleted" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "check" } })],
                1
              ),
              _c("span", [_vm._v("動画設定を変更しました")]),
            ])
          : _vm._e(),
        !_vm.validationCheck("all", true, true)
          ? _c("div", { staticClass: "saveButtonValidationError" }, [
              _c(
                "div",
                { staticClass: "icon" },
                [_c("fa-icon", { attrs: { icon: "exclamation-circle" } })],
                1
              ),
              _c("span", [_vm._v("不備のある項目が存在します")]),
            ])
          : _vm._e(),
        _c(
          "button",
          {
            attrs: {
              disabled:
                !_vm.model.saveButtonActive ||
                !_vm.validationCheck("all", true, true),
            },
            on: { click: _vm.save },
          },
          [
            _vm.model.saveButtonLoading
              ? _c("div", { staticClass: "spinner bubble" })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("fa-icon", { attrs: { icon: "save" } })
              : _vm._e(),
            !_vm.model.saveButtonLoading
              ? _c("span", [_vm._v("動画設定を適用")])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { attrs: { clss: "step" } }, [
      _c("li", [_vm._v("1.アップロード")]),
      _c("li", [_vm._v("2.ファイル変換")]),
      _c("li", [_vm._v("3.準備完了")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }