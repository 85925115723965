var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag" },
    _vm._l(_vm.value.values, function (item1) {
      return _c("div", { key: _vm.value.values.indexOf(item1) }, [
        _c(
          "div",
          {
            class: {
              focus:
                _vm.focusIndex === _vm.value.values.indexOf(item1).toString(),
              error: _vm.value.isError(_vm.value.values.indexOf(item1)),
            },
          },
          [
            _c("div", { staticClass: "textBlock" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: item1.value,
                    expression: "item1.value",
                  },
                ],
                attrs: {
                  type: "text",
                  id: "tag" + _vm.value.values.indexOf(item1),
                },
                domProps: { value: item1.value },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.trigger.apply(null, arguments)
                  },
                  focus: function ($event) {
                    _vm.focusIn(_vm.value.values.indexOf(item1))
                  },
                  blur: function ($event) {
                    return _vm.focusOut()
                  },
                  change: _vm.onChange,
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item1, "value", $event.target.value)
                    },
                    _vm.onInput,
                  ],
                },
              }),
              _c("div", { staticClass: "dummy" }, [
                _vm._v(_vm._s(item1.value)),
              ]),
            ]),
            _c(
              "button",
              {
                attrs: {
                  disabled:
                    _vm.value.values.indexOf(item1) ==
                    _vm.value.values.length - 1,
                  tabindex: "-1",
                },
                on: {
                  click: function ($event) {
                    _vm.deleteTag(_vm.value.values.indexOf(item1))
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "times" } })],
              1
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }