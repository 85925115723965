export class Model {
  public loading: boolean = true;
  public contentCode: string = "";
  public saveButtonActive: boolean = true;
  public saveButtonLoading: boolean = false;
  public saveButtonCompleted: boolean = false;
  public title: InputText = new InputText(100, 5);
  public explanation: InputText = new InputText(1000);
  public tags: InputTags = new InputTags(50);
  public contentGroups: Array<ContentGroup> = [];
  public plans: Array<Plan> = [];
  public sale: boolean = false;
  public price: InputPrice = new InputPrice();
  public day: InputText = new InputText(3);
  public limited: boolean = true;
  public thumbnail: InputThumbnail = new InputThumbnail();
  public media: InputMedia = new InputMedia();
  public contentId: number | null = null;
}
export class InputThumbnail {
  public constructor() {
    let testPattern = 0;
    testPattern = 0;
    switch (testPattern) {
      case 1:
        this.base64Src = "test";
        this.type = InputThumbnailType.fileUpload;
        break;
      case 2:
        this.snapShotSelected = 25;
        this.type = InputThumbnailType.fileUpload;
        break;
    }
  }
  public type: InputThumbnailType = InputThumbnailType.fileUpload;
  public get isSetSrc(): boolean {
    return (
      this.base64Src != null ||
      this.contentTempFileImageUrl != null ||
      this.defaultFileImageUrl != null
    );
  }
  public defaultFileImageUrl: string | null = null;
  public loacalFilePath: string | null = null;
  public base64Src: string | null = null;
  public get contentTempFileImageUrl(): string | null {
    if (this.snapShotSelected != null) {
      if (this.thumbnailBaseUrlTemp != null) {
        return (
          this.thumbnailBaseUrlTemp +
          "snapShot/" +
          ("00000" + this.snapShotSelectedNumber).slice(-5) +
          ".png"
        );
      } else if (this.thumbnailBaseUrlDefault != null) {
        return (
          this.thumbnailBaseUrlDefault +
          "snapShot/" +
          ("00000" + this.snapShotSelectedNumber).slice(-5) +
          ".png"
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  public snapShotWidth: number | null = null;
  public snapShotHeight: number | null = null;
  public snapShotCount: number | null = null;
  public thumbnailBaseUrlDefault: string | null = null;
  public thumbnailBaseUrlTemp: string | null = null;
  public get snapShotImageUrl(): string | null {
    if (this.thumbnailBaseUrlTemp != null) {
      return this.thumbnailBaseUrlTemp + "snapShot.png";
    } else if (this.thumbnailBaseUrlDefault != null) {
      return this.thumbnailBaseUrlDefault + "snapShot.png";
    } else {
      return null;
    }
  }
  public get snapShotSelectedNumber(): number | null {
    let imgNumber = null;
    if (this.snapShotList != null) {
      for (const item1 of this.snapShotList) {
        if (item1.count == this.snapShotSelected) {
          imgNumber = item1.number;
        }
      }
    }
    return imgNumber;
  }
  public snapShotList: Array<InputThumbnailSnapShotListItem> | null = null;
  public snapShotSelected: number | null = null;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export enum InputThumbnailType {
  fileUpload = "fileUpload",
  mediaSelect = "mediaSelect",
}
export class InputThumbnailSnapShotListItem {
  public constructor(
    public count: number,
    public left: number,
    public number: number
  ) {}
}
export class InputMedia {
  public constructor() {
    let testPattern = 0;
    testPattern = 0;
    switch (testPattern) {
      case 1:
        this.target = InputMediaTarget.temp;
        this.status = InputMediaStatus.loading;
        break;
      case 2:
        this.status = InputMediaStatus.ready;
        break;
      case 3:
        this.status = InputMediaStatus.uploading;
        this.params = new InputMediaParams();
        this.params.statusText = "ステータステキスト";
        this.params.progress = 30;
        this.params.cancel = false;
        break;
      case 4:
        this.status = InputMediaStatus.converting;
        this.target = InputMediaTarget.temp;
        this.params = new InputMediaParams();
        this.params.statusText = "ステータステキスト";
        this.params.progress = 30;
        this.params.cancel = false;
        break;
      case 5:
        this.status = InputMediaStatus.completed;
        break;
    }
  }
  public target: InputMediaTarget = InputMediaTarget.temp;
  public status: InputMediaStatus = InputMediaStatus.loading;
  public params: InputMediaParams = new InputMediaParams();
  public contentTempFileId: number | null = null;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export enum InputMediaTarget {
  temp = "temp",
  default = "default",
}
export enum InputMediaStatus {
  loading = "loading",
  ready = "ready",
  uploading = "uploading",
  converting = "converting",
  completed = "completed",
}
export class InputMediaParams {
  public statusText: string = "";
  public cancel: boolean = false;
  public progress: number = 0;
}
export class InputText {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public value: string = "";
  public maxLength: number = 0;
  public minLength: number = 0;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
}
export class InputTags {
  public constructor(maxLength: number, minLength = 0) {
    this.maxLength = maxLength;
    this.minLength = minLength;
  }
  public values: Array<InputTag> = [];
  public maxLength: number;
  public minLength: number;
  public errors: Array<Error> = new Array<Error>();
  public isError(index: number | null = null): boolean {
    if (index != null) {
      let result = false;
      for (let i = 0; i < this.errors.length; i++) {
        if (this.errors[i].index == index) {
          result = true;
        }
      }
      return result;
    } else {
      return this.errors.length > 0;
    }
  }
  public add() {
    this.values.push(new InputTag(this.maxLength));
  }
}
export class InputTag {
  public constructor(maxLength: number, minLength = 0, value = "") {
    this.maxLength = maxLength;
    this.minLength = minLength;
    this.value = value;
  }
  public value: string = "";
  public maxLength: number;
  public minLength: number;
}
export class InputPrice {
  public value: string = "";
  public maxLength: number = 6;
  public errors: Array<Error> = new Array<Error>();
  get isError(): boolean {
    return this.errors.length > 0;
  }
  public focus: boolean = false;
  public dummy: Array<string> = [" ", " ", " ", " ", " ", " ", " "];
}
export class Error {
  public constructor(message: string, index: number | null = null) {
    this.message = message;
    this.index = index;
  }
  public message: string;
  public index: number | null;
}
export class ContentGroup {
  public constructor(
    public code: string,
    public name: string,
    public checked: boolean
  ) {}
}
export class Plan {
  public constructor(
    public code: string,
    public name: string,
    public checked: boolean
  ) {}
}
