import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";

export class Model {}

@Component
export default class extends Vue {
  @Prop()
  public value: any;
  public focusIndex: string = "";
  public trigger(event: any) {
    if (event.keyCode !== 13) return;
    document
      .getElementById(
        "tag" + (Number(event.target.id.replace(/[^0-9]/g, "")) + 1)
      )
      ?.focus();
  }

  @Watch("value.values", { deep: true })
  public changeTags() {
    if (this.value.values[this.value.values.length - 1].value != "") {
      this.value.add();
    }
  }
  @Emit("onChange")
  public deleteTag(index: number) {
    if (this.value.values.length >= 2) {
      this.value.values.splice(index, 1);
    }
  }
  public focusIn(index: number) {
    this.focusIndex = index.toString();
  }
  public focusOut() {
    this.focusIndex = "";
  }
  @Emit("onChange")
  public onChange() {
    //
  }
  @Emit("onInput")
  public onInput() {
    //
  }
}
