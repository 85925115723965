import { Component, Vue } from "vue-property-decorator";
import Hls from "hls.js";

export class Model {}

@Component
export default class extends Vue {
  public constructor() {
    super();
    this.hls = new Hls();
  }
  public hls: Hls;

  public setupVideo(url: string): void {
    this.$nextTick(function() {
      const video = document.getElementById("videoHls") as HTMLMediaElement;
      if (Hls.isSupported()) {
        const hls = this.hls;
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function() {
          video.pause();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url;
        video.addEventListener("loadedmetadata", function() {
          video.pause();
        });
      }
    });
  }
  public destroyVideo(): void {
    this.hls.destroy();
    this.hls = new Hls();
  }
}
